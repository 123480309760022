.primary-button {
  button {
    background: #014C8C;
    border-color: #014C8C;
  }
}

//media
.order-modal, .order-modal-manually {
  min-height: 480px;

  .max-h-\[90vh\] {
    max-height: 100%;
  }
}

@media (max-height: 500px) {
  .order-modal-container {
    .order-modal {
      > div {
        //height: 100% !important;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (min-width: 1025px) {
  .order-modal-container {
    .order-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .order-modal-container {
    .order-modal {
      > div {
        height: unset !important;
      }

      .grid-cols-custom {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .gap-custom {
        gap: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .order-modal-container {
    .order-modal {
      .grid-cols-custom {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        div[class='flex'] {
          margin-bottom: 1rem;
        }
      }
    }
  }
}


.hover\:bg-blue-100:hover {
  background-color: rgb(171 153 115);
}

.multi-select, .review {
  .select-custom .react-select__control {
    width: 300px;
    background: white !important;
    cursor: pointer;
  }
}

//custom flatpickr-input
.flatpickr-input[readonly] {
  width: 100%;
}
//
.modal-error-report{
  overflow-wrap: anywhere;
  word-break: break-all;
}